import { Component } from "react";
import Stack from '@mui/material/Stack';

import './scaleReportsDashboard.styles.css';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Backpack, ReceiptLong, RoomService, TimerSharp } from "@mui/icons-material";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { grey, yellow, blue } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ScaleReportsDashboardController } from "./controller/scaleReportsDashboard.controller";
import { TimeRangeFilter } from "../scaleReportsPage/types/types";
import { DashboardWidgetsData } from "./types/types";
import TopWidget from "./innerComponents/topWidget.component";
import DoughnutWidget from "./innerComponents/doughnutWidget.component";
import BarChartWidget from "./innerComponents/barChartWidget.component";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";

export interface ScaleReportsDashboardProps {

}

export interface ScaleReportsDashboardState {
    dashboardData: DashboardWidgetsData;
    fetchedDataTimerange: TimeRangeFilter;
    openFromDatePicker: boolean;
    openToDatePicker: boolean;
    selectedDate: string;
    fromDate: any;
    toDate: any;
    minDate: any;
    maxDate: any;
}

class ScaleReportsDashboard extends Component<ScaleReportsDashboardProps, ScaleReportsDashboardState> {
    private scaleReportsDashboardController = new ScaleReportsDashboardController();

    constructor(props: any) {
        super(props);

        this.state = this.getEmptyState();

        // bind here event handlers
        this.changeFromDate = this.changeFromDate.bind(this);
        this.changeToDate = this.changeToDate.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.applyDateFilter = this.applyDateFilter.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this);
    }

    getEmptyState(): ScaleReportsDashboardState {
        return {
            dashboardData: this.getEmptyDashboardData(),
            fetchedDataTimerange: this.getTimeRangeFilter('today'),
            openFromDatePicker: false,
            openToDatePicker: false,
            selectedDate: "today",
            fromDate: undefined,
            toDate: undefined,
            minDate: undefined,
            maxDate: undefined
        }
    }

    getTimeRangeFilter(input: string) {
        let startInterval = new Date();

        switch(input) {
            case "last-24h":
                startInterval.setDate(startInterval.getDate() - 1);
                break;
            case "last-week":
                startInterval.setDate(startInterval.getDate() - 7);
                break;
            case "last-month":
                startInterval.setMonth(startInterval.getMonth() - 1);
                break;
            default: // today
                startInterval.setHours(0);
                break;
        }

        const timeRangeFilter: TimeRangeFilter = {
            name: 'timeRange',
            values: {
                startInterval: startInterval.getTime(),
                endInterval: (new Date()).getTime()
            }
        }

        return timeRangeFilter;
    }

    getEmptyDashboardData(): DashboardWidgetsData {
        return {
            totalNetWeightWidgetText: '',
            numberOfProductsWidgetText: '',
            numberOfWeighingsWidgetText: '',
            averageLoadingTimeWidgetText: '',
            netWeighsWidgetLabels: [],
            netWeighsWidgetValues: [],
            mostWeighedProductsWidgetData: [],
            topClientsWidgetLabels: [],
            topClientsWidgetValues: [],
            topClientsWidgetTotalUnitMeasure: '',
            topClientsWidgetTotalValue: '',
            doughnutWidgetsTotal: 0,
            invoicedDoughnutValue: 0,
            manualDoughnutValue: 0,
            overweightDoughnutValue: 0,
            withUitCodeDoughnutValue: 0,
        }
    }

    async componentDidMount() {
        await this.getDashboardData(this.getTimeRangeFilter('today'))
    }

    private changeFromDate(newValue: any) {
        if(newValue) {
            this.setState({
                minDate: newValue as Date,
                fromDate: newValue as Date,
                selectedDate: ''
            }, () => { this.applyDateFilter() })
        }
    }

    private changeToDate(newValue: any) {
        if(newValue) {
            this.setState({
                maxDate: newValue as Date,
                toDate: newValue as Date
            }, () => { this.applyDateFilter() })
        }
    }

    private handleDateChange(newValue: any) {
        switch(newValue.target.value) {
            case "last-24h":
            case "last-week":
            case "last-month":
                break;
            default: // today
                newValue.target.value = "today";
                break;
        }

        this.setState({
            selectedDate: newValue.target.value,
            fromDate: null,
            toDate: null
        });

        this.getDashboardData(this.getTimeRangeFilter(newValue.target.value));
    }

    private applyDateFilter() {
        if(this.state.fromDate) {
            let toDate = new Date();

            if(this.state.toDate) {
                toDate = this.state.toDate;
            } else {
                this.setState({
                    toDate: toDate
                });
            }

            const timeRangeFilter: TimeRangeFilter = {
                name: 'timeRange',
                values: {
                    startInterval: (this.state.fromDate as Date).getTime(),
                    endInterval: toDate.getTime()
                }
            }

            this.getDashboardData(timeRangeFilter);
        }
    }

    private async getDashboardData(timeRangeFilter: TimeRangeFilter) {
        if(timeRangeFilter === this.state.fetchedDataTimerange) {
            return;
        }

        try {
            const data = await this.scaleReportsDashboardController.getInvoices(timeRangeFilter);

            data.netWeighsWidgetLabels = data.netWeighsWidgetLabels.reverse();

            this.setState({
                dashboardData: data,
                fetchedDataTimerange: timeRangeFilter
            });
        } catch(err: any) {
            dispatchRequestErrorEvent(err);
        }
    }
    
    render() {
        

        const topProductsTable = this.state.dashboardData.mostWeighedProductsWidgetData.map(entry => {
            const productName = entry.name;
            const productValue = entry.totalWeight;

            return <TableRow>
                        <TableCell scope="row">
                            {productName}
                        </TableCell>
                        <TableCell scope="row" align="right">
                            {productValue}
                        </TableCell>
                    </TableRow>
        });

        return (
            <div className='etportal-page-container'>
                <Stack spacing={2}>
                    <h1 className='module-title'>Dashboard</h1>
                    <ToggleButtonGroup value={this.state.selectedDate} exclusive aria-label="date-picker" sx={{
                        '& .MuiToggleButton-root': {border: 0},
                        '& .MuiToggleButton-root.Mui-selected': {
                            textDecoration: 'underline',
                            color: '#666CFF',
                            backgroundColor: '#ebebeb'
                        },
                        '& .MuiCard-root': {
                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                        }
                        }}

                        onChange={this.handleDateChange}
                        >
                        <ToggleButton value="today" aria-label="date-today">
                            Astazi
                        </ToggleButton>
                        <ToggleButton value="last-24h" aria-label="last-24h">
                            Ultimele 24 ore
                        </ToggleButton>
                        <ToggleButton value="last-week" aria-label="date-last-week">
                            Ultima saptamana
                        </ToggleButton>
                        <ToggleButton value="last-month" aria-label="date-last-month">
                            Ultima luna
                        </ToggleButton>
                        <Box sx={{flexGrow: 1}}></Box>
                        <DatePicker 
                            label="De la" 
                            sx={{marginRight: 2 }}
                            value={this.state.fromDate}
                            open={this.state.openFromDatePicker}
                            onClose={() => this.setState({openFromDatePicker: false})}
                            slotProps={{
                                textField: {
                                    onClick: () => this.setState({openFromDatePicker: true}),             
                                },
                            }}
                            onChange={this.changeFromDate}
                            maxDate={this.state.maxDate}
                            disableFuture 
                            disableOpenPicker
                        />
                        <DatePicker 
                            label="Pana la" 
                            value={this.state.toDate}
                            open={this.state.openToDatePicker}
                            onClose={() => this.setState({openToDatePicker: false})}
                            slotProps={{
                                textField: {
                                    onClick: () => this.setState({openToDatePicker: true}),             
                                },
                            }}
                            onChange={this.changeToDate}
                            minDate={this.state.minDate}
                            disableFuture 
                            disableOpenPicker
                        />
                    </ToggleButtonGroup>
                    <div className='general-information'>
                        <Stack direction="row" spacing={2}>
                            <TopWidget 
                                widgetIcon={<Backpack sx={{ color: yellow[700], background: yellow[100], padding: 0.5 }} className='material-small-icon'/>}
                                widgetTitle={"Total NET cantarit (t)"}
                                widgetText={this.state.dashboardData.totalNetWeightWidgetText}
                            />
                            <TopWidget 
                                widgetIcon={<ReceiptLong sx={{ color: blue[700], background: blue[50], padding: 0.5 }} className='material-small-icon'/>}
                                widgetTitle={"Numar cantariri"}
                                widgetText={this.state.dashboardData.numberOfWeighingsWidgetText}
                            />
                            <TopWidget 
                                widgetIcon={<RoomService sx={{ color: grey[500], background: grey[300], padding: 0.5 }} className='material-small-icon'/>}
                                widgetTitle={"Numar produse"}
                                widgetText={this.state.dashboardData.numberOfProductsWidgetText}
                            />
                            <TopWidget 
                                widgetIcon={<TimerSharp sx={{ color: grey[500], background: grey[300], padding: 0.5 }} className='material-small-icon'/>}
                                widgetTitle={"Durata medie de incarcare"}
                                widgetText={this.state.dashboardData.averageLoadingTimeWidgetText}
                            />
                        </Stack>
                    </div>
                    <div className='general-information'>
                    <BarChartWidget 
                                title={"Net cantarit (t)"}
                                chartWidth={1519}
                                chartProps={{
                                    data: this.state.dashboardData.netWeighsWidgetValues,
                                    labels: this.state.dashboardData.netWeighsWidgetLabels,
                                    margins: {
                                        right: 0,
                                        bottom: 30,
                                    },
                                    colours: ['rgba(243,156, 18, 0.9)'],
                                    roundedBorders: true,
                                    skipTicks: (value, index) => {
                                        value = value + "";
                                        let skipValue = 1;
                                        let empiricValue = 21;
                                        while(this.state.dashboardData.netWeighsWidgetValues.length > empiricValue * skipValue) {
                                            skipValue *= 2;
                                        }
                                        return index % skipValue === 0;
                                    },
                                    showYAxisValues: true
                                }}
                            />
                    </div>
                    <div className='general-information'>
                        <Stack direction="row" spacing={2}>
                            
                            <Card sx={{borderRadius: 2, flexGrow: 1}}>
                                <Stack sx={{padding: 1.5}} spacing={2}>
                                    <Box>
                                        <Typography sx={{ color: grey[700]}} variant="h6" component="div">
                                            <Box fontWeight='fontWeightMedium'>Cele mai cantarite produse (t)</Box>
                                        </Typography>
                                    </Box>
                                    <Box>
                                    <Divider />
                                        <TableContainer>
                                            <Table sx={{ '& .MuiTableCell-root': {padding: 1.5}}}>
                                                <TableBody>
                                                    {topProductsTable}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Stack>
                            </Card>
                            <BarChartWidget 
                                title={"Top clienti"}
                                chartWidth={759}
                                underTitleElement={
                                    <Typography sx={{ color: grey[700], marginTop: 0}} variant="body2" component="div">
                                        <Stack direction="row" spacing={0.5}>
                                            <Box>Total</Box>
                                            <Box fontWeight='fontWeightMedium'>{this.state.dashboardData.topClientsWidgetTotalValue}</Box>
                                            <Box>{this.state.dashboardData.topClientsWidgetTotalUnitMeasure}</Box>
                                        </Stack>
                                    </Typography>
                                }
                                chartProps={{
                                    data: this.state.dashboardData.topClientsWidgetValues,
                                    labels: this.state.dashboardData.topClientsWidgetLabels,
                                    margins: {
                                        left: 290,
                                        right: 30,
                                        top: 0,
                                        bottom: 30,
                                    },
                                    colours: ["#787EFF", "#83E542", "#FDBE42", "#40CDFA", "#FF625F"],
                                    roundedBorders: true,
                                    showValuesOnBars: true,
                                    isHorizontal: true
                                }}
                            />
                        </Stack>
                    </div>
                    <div className='general-information'>
                        <Stack direction="row" spacing={2}>
                            <DoughnutWidget
                                valueLabel={"Facturat"}
                                doughnutValue={this.state.dashboardData.invoicedDoughnutValue}
                                doughnutTotal={this.state.dashboardData.doughnutWidgetsTotal}
                                fillLabel={"Facturate"}
                                unfilledLabel={"Nefacturate"}
                                doughnutChartColour={"#666CFF"}
                            />
                            <DoughnutWidget
                                valueLabel={"Manual"}
                                doughnutValue={this.state.dashboardData.manualDoughnutValue}
                                doughnutTotal={this.state.dashboardData.doughnutWidgetsTotal}
                                fillLabel={"Manuale"}
                                unfilledLabel={""}
                                doughnutChartColour={"#FDB528"}
                            />
                            <DoughnutWidget
                                valueLabel={"Supraincarcat"}
                                doughnutValue={this.state.dashboardData.overweightDoughnutValue}
                                doughnutTotal={this.state.dashboardData.doughnutWidgetsTotal}
                                fillLabel={"Supraincarcat"}
                                unfilledLabel={""}
                                doughnutChartColour={"#FF4D49"}
                            />
                            <DoughnutWidget
                                valueLabel={"Cu UIT"}
                                doughnutValue={this.state.dashboardData.withUitCodeDoughnutValue}
                                doughnutTotal={this.state.dashboardData.doughnutWidgetsTotal}
                                fillLabel={"Cu cod UIT"}
                                unfilledLabel={"Fara cod UIT"}
                                doughnutChartColour={"#83E542"}
                            />
                        </Stack>
                    </div>
                </Stack>
            </div>
        )
    }
}

export default ScaleReportsDashboard;