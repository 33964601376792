import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { CONSTANTS } from '../../../lib/constants';
import { RootState } from '../../../redux/types';
import AdminPanel  from '../adminPanelPage/adminPanel.component';
import ETPopup from '../../ETComponents/ETPopup/etpopup.component';
import Menu from '../menu/menu.component';
import PurchaseOrderPage from '../purchaseOrdersPage/purchaseOrdersPage.component';
import ProfilePage from '../profilePage/profilePage.component';
import WasteStockPage from '../wasteStockPage/wasteStockPage.component';
import AnafOauthPage from '../anafOauthPage/anafOauthPage.component';
import InvoicePage from '../invoicePage/invoicePage.component';
import ScaleReportsPage from '../scaleReportsPage/scaleReports.component';

import './landingPage.styles.css'
import CompanyProfilePage from '../companyProfilePage/companyProfilePage.component';
import ScaleReportsDashboard from '../scaleReportsDashboardPage/scaleReportsDashboard.component';
import ConveyorReportsPage from '../conveyorReportsPage/conveyorReports.component';

const LandingPage = () => {
    const moduleToDisplay = useSelector((state: RootState) => state.application.activeModule);
    let activeModule:ReactElement;

    switch (moduleToDisplay) {
        case CONSTANTS.MODULES.PROFILE_PAGE:
            activeModule = <ProfilePage />;
            break;
        case CONSTANTS.MODULES.SUPERADMIN_PAGE:
            activeModule = <AdminPanel />;
            break;
        case CONSTANTS.MODULES.SCALE_REPORTS_PAGE:
            activeModule = <ScaleReportsPage />;
            break;
        case CONSTANTS.MODULES.PURCHASE_ORDERS_PAGE:
            activeModule = <PurchaseOrderPage />;
            break;
        case CONSTANTS.MODULES.WASTE_MANAGEMENT_PAGE:
            activeModule = <WasteStockPage />;
            break;
        case CONSTANTS.MODULES.ANAF_OAUTH_PAGE:
            activeModule = <AnafOauthPage />
            break;
        case CONSTANTS.MODULES.INVOICE_PAGE:
            activeModule = <InvoicePage />;
            break;
        case CONSTANTS.MODULES.COMPANY_PROFILE_PAGE:
            activeModule = <CompanyProfilePage />;
            break;
        case CONSTANTS.MODULES.SCALE_REPORTS_DASHBOARD_PAGE:
            activeModule = <ScaleReportsDashboard />;
            break;
        case CONSTANTS.MODULES.CONVEYOR_REPORTS_PAGE:
            activeModule = <ConveyorReportsPage />;
            break;
        default:
            // we will set here the default landing page.
            // the default is set on login programatically in client/src/redux/actions/auth.ts
            activeModule = <ScaleReportsDashboard />;
    }

    return (
        <div id='landingPage-container'>
            <ETPopup />
            <Menu />
            {activeModule}
        </div>
    )
}

export default LandingPage;