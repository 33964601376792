import { Component, ReactElement } from "react";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";
import { ConveyorReport, TimeRangeFilter } from "./types/types";
import { ConveyorReportsController } from "./controller/conveyorReports.controller";
import { TableVirtuoso } from "react-virtuoso";

import './conveyorReports.styles.css';
import EtTimeRangeFilter from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.component";
import { TimeRange } from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";

interface ConveyorReportsPageState {
    conveyorReports: Array<ConveyorReport>;
    totalWeight: number;
    timeRange: TimeRange;
}

interface ConveyorReportsPageProps {
}

class ConveyorReportsPage extends Component<ConveyorReportsPageProps, ConveyorReportsPageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            conveyorReports: [],
            totalWeight: 0,
            timeRange: {
                startInterval: 0,
                endInterval: 0
            },
        }

        // bind here event handlers
        this.loadConveyorReportsData = this.loadConveyorReportsData.bind(this);
        this.timeRangeFilterChanged = this.timeRangeFilterChanged.bind(this);
    }

    async componentDidMount() {
        await this.loadConveyorReportsData();
    }

    async loadConveyorReportsData()
    {
        try {
            const timeRangeFilter: TimeRangeFilter = {
                name: 'timeRange',
                values: this.state.timeRange
            }
            
            const conveyorReports = await ConveyorReportsController.getReports(timeRangeFilter);
            let totalWeight = 0;
            totalWeight = conveyorReports.reduce((totalWeight, conveyorReport) => totalWeight + (+conveyorReport.value), 0);

            this.setState({
                conveyorReports: conveyorReports,
                totalWeight: totalWeight
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }
    }

    private timeRangeFilterChanged(selectedTimeRange: TimeRange): void {
        this.setState(
            {
                timeRange: selectedTimeRange
            },
            () => this.loadConveyorReportsData()
        );
    }

    render(): ReactElement { 
        
        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Rapoarte conveior</h1>
                <div id="table-filters-container">
                    <EtTimeRangeFilter onChange={this.timeRangeFilterChanged}/>
                </div>
                <div className="conveyor-reports-virtualized-table-container">
                    <TableVirtuoso 
                        className={"conveyor-reports-virtualized-table"}
                        data={this.state.conveyorReports}
                        fixedHeaderContent={() => (
                            <tr>
                                <th>
                                    Data
                                </th>
                                <th>
                                    Valoare (kg)
                                </th>
                            </tr>
                        )}
                        itemContent={(index, report: ConveyorReport) => {
                            const reportDate = new Date(report.dateInUTC);
                            const month = new Intl.DateTimeFormat('en', {month: 'short'}).format(reportDate);
                            const day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(reportDate);
                            const hour = new Intl.DateTimeFormat('en', {hour:'numeric', hour12:false}).format(reportDate);
                            const minute = new Intl.DateTimeFormat('en', {minute: 'numeric'}).format(reportDate);
                            const parsedMinute = parseInt(minute) <= 9 ? '0' + minute : minute;
                            
                            return (
                                <>
                                    <td  id={index + ""}  className='eclipse-text'>{`${day} ${month} ${hour}:${parsedMinute}`}</td>
                                    <td className='eclipse-text' title={`${report.id}`}>{report.value}</td>
                                </>
                            )
                        }}
                    />
                </div>
                <div id="total-weight">
                    <p>Greutate totala: {this.state.totalWeight} kg</p>
                </div>
            </div>
        )
    }
}

export default ConveyorReportsPage;